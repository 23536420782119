import React, { useEffect, useState } from 'react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { IoEye, IoClose } from 'react-icons/io5';

import Logo from '@/assets/images/logo.png';
import FooterLinks from '@/components/Auth/FooterLinks';
import { useAuth } from '@/context/AuthContext';
import { Button } from '@/elements';
import { UserRole } from '@/generated/core.graphql';

import LoginImage from './LoginImage.jpeg';

export default function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const router = useRouter();
  const { source } = router.query;
  const { isAuthenticated, signIn, isSigningIn, signInError, userRole } =
    useAuth();
  const toast = useToast();
  const [show, setShow] = useState(false);

  const onSubmit = async (values) => {
    if (values.email && values.password) {
      signIn(values.email, values.password);
    }
  };

  // redirect if signed in
  useEffect(() => {
    if (isAuthenticated) {
      if (userRole === UserRole.Participant) {
        router.push('/participant/dashboard');
      } else {
        router.push('/admin/clients?expandSidebar=true');
      }
    }
  }, [isAuthenticated, router, userRole]);

  useEffect(() => {
    if (source === 'resetPassword') {
      toast({
        status: 'success',
        title: 'Password successfully updated',
        description: 'Please log in.',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }, [isAuthenticated, router, source, toast]);

  useEffect(() => {
    if (signInError) {
      toast({
        status: 'error',
        title: 'Invalid user credentials',
        description: 'Please check your username and password.',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }, [signInError, toast]);

  const toggleShowPassword = () => setShow(!show);

  return (
    <SimpleGrid
      backgroundColor='white'
      columns={{ sm: 1, md: 2 }}
      height='100vh'
    >
      <Box
        display={{ sm: 'none', md: 'initial' }}
        height='80%'
        margin='auto'
        width='80%'
      >
        <Image
          alt='intro image'
          src={LoginImage}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '20px',
          }}
        />
      </Box>
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        marginTop='10%'
        p={{
          base: 'md',
          sm: 'md',
          md: 'lg',
          lg: 'xl',
        }}
      >
        <Stack gap='2.5rem' width='90%'>
          <Box h='8rem' w='16rem'>
            <Image
              alt='logo image'
              src={Logo}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box mb='2'>
            <Heading fontWeight={700} size='lg'>
              👋🏻 Welcome back{' '}
            </Heading>
          </Box>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={3}>
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    {...register('email', {
                      required: 'This field is required',
                    })}
                    fontSize='md'
                    id='email'
                    placeholder='Email'
                    size='lg'
                    type='email'
                  />
                  {errors.email && (
                    <FormErrorMessage>
                      {errors.email.message as string}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <InputGroup>
                    <Input
                      {...register('password', {
                        required: 'This field is required',
                      })}
                      fontSize='md'
                      id='password'
                      placeholder='Password'
                      size='lg'
                      type={show ? 'text' : 'password'}
                    />
                    <InputRightElement height='full'>
                      {show ? (
                        <Icon
                          as={IoClose}
                          color='gray.500'
                          onClick={toggleShowPassword}
                        />
                      ) : (
                        <Icon
                          as={IoEye}
                          color='gray.300'
                          onClick={toggleShowPassword}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <FormErrorMessage>
                      {errors.password.message as string}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='space-between'
                >
                  <Link
                    color='electricBlue.500'
                    fontWeight={600}
                    href='/auth/forgot-password'
                  >
                    Forgot Password?
                  </Link>
                </Box>
              </Stack>

              <Button
                colorScheme='electricBlue'
                fontSize='md'
                isLoading={isSigningIn}
                minWidth={200}
                mt='3rem'
                size='lg'
                type='submit'
                variant='solid'
                width='full'
              >
                Log In
              </Button>
            </form>
          </Box>
        </Stack>
        <FooterLinks />
      </Box>
    </SimpleGrid>
  );
}
