import { Box } from '@chakra-ui/react';

export default function FooterLinks() {
  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      width='24rem'
    >
      {/* <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='/auth/forgot-password'
      >
        Privacy & Policy
      </Link>
      <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='/auth/forgot-password'
      >
        Terms & Conditions
      </Link>
      <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='/auth/forgot-password'
      >
        Help
      </Link> */}
    </Box>
  );
}
